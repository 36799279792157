.profile.layout {
	display: grid;
	grid-template-areas:
		'user personal location'
		'user work map';
	grid-gap: 3rem;
	margin-bottom: 2rem;
}
.profile.layout .ui.header.header {
	text-transform: capitalize;
	letter-spacing: 0;
	font-weight: 500;
	font-size: 1.3rem;
	margin: 1.45rem 0 0;
}
.profile.layout .ui.header.header.heading {
	margin-top: 0;
	font-size: 1.2rem;
	color: rgb(41, 41, 41);
	margin-bottom: 0.35rem;
	position: relative;
}
.profile.layout .ui.header.header.heading .sub.header {
	margin-top: 0.15rem;
}
.profile.layout .ui.header.header .sub.header {
	color: rgb(197, 197, 197);
	font-size: 0.65em;
	margin-top: 0.33rem;
}
.profile.layout .ui.divider.content {
	max-width: 50%;
	margin: 1.45rem auto;
	border-top-color: rgba(0, 0, 0, 0.05);
}
.profile.layout .ui.divider.content.small {
	margin: 1.12rem auto;
	max-width: 85%;
}
.profile.layout .ui.divider.content.top {
	margin-top: 0.55rem;
}
.profile.layout ul.list {
	list-style: none;
	margin: 0;
	padding: 0;
	padding-top: 1rem;
}
.profile.layout ul.list li {
	margin-bottom: 1rem;
	color: rgb(112, 112, 112);
	display: flex;
}
.profile.layout ul.list li span {
	min-width: 100px;
	display: block;
}
.profile.layout ul.list li span.label {
	color: teal;
}
.profile.layout .widget.user {
	grid-area: user;
}
.profile.layout .widget.user .imagebox.img {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.profile.layout .widget.user span.label {
	min-width: 70px;
}
.profile.layout .widget.personal {
	grid-area: personal;
}
.profile.layout .widget.location {
	grid-area: location;
	min-width: 270px;
}
.profile.layout .widget.map {
	grid-area: map;
}
.profile.layout .widget.work {
	grid-area: work;
}
.profile.layout .widget.work span.label {
	min-width: auto;
	margin-right: 0.75rem;
}
.profile.layout .widget.work i.icon.edit {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	font-size: 0.97rem;
	color: blue;
}
