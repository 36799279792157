.lab-result section.result {
	width: 80%;
	margin: auto;
}
.lab-result section.result .list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.lab-result .result .ui.header.title {
	margin: 0 0 0.55rem;
	text-transform: capitalize;
	padding-top: 1.5rem;
}
.lab-result .result .ui.divider.content {
	margin: 0 0 1rem;
}
.lab-result .result .iw.text.list-item span {
	margin-right: 0.75rem;
	color: rgb(136, 136, 136);
}
.lab-result .result .iw.text.list-item {
	color: rgb(34, 34, 34);
}
.lab-result .result .ui.button.submit {
	font-size: 1.2rem;
	background: teal;
	color: white;
	padding: 0.75rem 1.15rem !important;
	font-weight: 300;
	text-transform: uppercase;
}
.lab-result .result .iw.text.warning {
	color: rgb(78, 23, 104);
	font-size: 0.9rem;
}
.lab-result .result .list .textfield {
	margin-top: 0;
}
