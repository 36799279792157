.iw.steppers {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
}
.iw.steppers .iw.step {
	display: inline-block;
	background: #2185d0;
	margin-right: 0.75rem;
	font-size: 1.011rem;
	text-align: center;
	padding: 3px 8px;
	border-radius: 2px;
	font-weight: 600;
	color: white;
	cursor: pointer;
}
.iw.steppers .iw.step:last-child {
	margin-right: 0;
}
.iw.steppers .iw.step.disabled {
	background: lightgray;
	color: gray;
}

.ui.grid.iw.list-forms .row {
	min-height: 100vh;
	position: relative;
	overflow-x: hidden;
	justify-content: center;
}
.ui.grid.iw.list-forms .row::-webkit-scrollbar {
	width: 0;
}
.ui.grid.iw.list-forms .row .column {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 740px !important;
	margin: auto;
	overflow-y: auto;
}
.ui.grid.iw.list-forms .row .column::-webkit-scrollbar {
	width: 0;
}
.ui.grid.iw.list-forms .row .column .col {
	display: none;
}

.ui.grid.iw.list-forms .row .column .col .active {
	display: block;
}
