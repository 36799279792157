.wrap-toolbar {
	display: flex;
	align-items: center;
	letter-spacing: 0;
	word-spacing: 0;
	font-size: 1rem;
	text-transform: capitalize;
}

.wrap-toolbar .ui.dropdown.user-tool {
	display: flex;
	align-items: center;
	margin-left: 0.75rem;
}
.wrap-toolbar .ui.dropdown.user-tool .item.user-item {
	padding: 1.12rem 1.55rem !important;
}
