.request-detail {
	padding: 0.8rem 0;
}
.request-detail .section {
	background: white;
	margin: 0.75rem 0;
	padding: 0.25rem 1rem;
}
.request-detail .section .ui.icon.button {
	padding: 0;
	background: none;
	color: gray;
	margin: 0 0.75rem;
	font-size: 0.9rem !important;
	font-weight: 300;
}
.request-detail .section .ui.icon.button .icon {
	font-size: 0.9rem !important;
	margin-right: 0.25rem !important;
}
.request-detail .section .ui.icon.button .icon.file {
	color: orange;
}
.request-detail .section .ui.icon.button .icon.print {
	color: blue;
}
.request-detail .section .ui.icon.button .icon.check {
	color: teal;
}
.request-detail .section .iw.text.details-text {
	margin: 0 0 0rem;
	font-size: 0.9rem;
	color: rgb(22, 22, 22);
}
.request-detail .section .iw.text.details-text span {
	color: rgb(161, 161, 161);
	margin-right: 0.55rem;
}
.request-detail .section .imagebox {
	margin: 1rem auto;
	display: block;
}
.request-detail .section.patient,
.request-detail .section .midwife {
	display: flex;
	align-items: center;
}
.request-detail .section.patient div.patient-content,
.request-detail .section .midwife div.content {
	min-width: 280px;
}
.request-detail .section.tabs .ui.text.menu {
	margin: 0;
}
.request-detail .section.tabs .ui.bottom.attached.segment.active.tab {
	border: none;
	padding: 0;
}
.request-detail .section .clinic,
.request-detail .section .request-overview {
	padding: 0.55rem;
}
.request-detail .section .clinic .iw.text.details-text {
	font-size: 0.96rem;
}
.request-detail .section .request-overview div.space {
	padding-bottom: 1rem;
}
.request-detail .section.tabs .ui.text.menu .active.item {
	color: var(--primary-theme-color);
}
.request-detail .section.tabs .ui.text.menu .item {
	color: var(--secondary-text-theme-color);
}
.request-detail .iw.text.status {
	margin: 0 0.55rem !important;
	padding: 0.35rem 0.75rem !important;
	font-size: 0.9rem;
}
