/* dashboard home styles */
.container.overview {
	box-sizing: border-box;
	position: relative;
}
.container.overview.contain .iw-blks {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-bottom: 3rem;
}
.container.overview .iw-blks .iw-blk {
	display: block;
	font-size: 1rem;
	text-align: center;
	text-transform: capitalize;
	color: teal;
	width: 250px;
}
.container.overview .iw-blks .iw-blk .icon.iw-icon {
	display: block;
	margin: 0 auto 0.5rem !important;
	/* background: rgb(116, 177, 222); */
	font-size: 2.4rem;
	width: 85px;
	height: 85px;
	position: relative;
	border-radius: 50%;
	transition: 300ms linear;
}
.container.overview .iw-blks .iw-blk .icon.iw-icon:before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.container.overview .iw-blks .iw-blk .icon.iw-icon:hover {
	/* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.125); */
	background: #ffffff;
}
.container.overview .iw-blks .iw-blk .iw-name {
	/* color: #464646; */
	font-weight: 500;
}
.container.overview .grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 5rem;
}
