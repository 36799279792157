* {
	box-sizing: border-box;
}
.landing.wrapper {
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: -350px 100px !important;
	min-height: 100vh;
}

.landing .content {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	flex-direction: column;
}
.landing .content img.image {
	width: 370px;
	display: block;
	order: 0;
	margin-bottom: 2rem;
}
.landing .content .desc {
	order: 1;
	text-align: center;
}
.landing .ui.header.title {
	margin: 0;
	margin: 2rem 0 3rem;
	font-size: 3.2rem;
	color: teal;
	/* font-family: 'Open sans'; */
	font-weight: bold;
}
.landing .ui.header.title .sub.header {
	margin-top: 0.25rem;
	color: rgb(128, 128, 128);
	letter-spacing: 0px;
}
.landing .content a.cta {
	outline: none;
	font-size: 1.2rem;
	background: teal;
	color: white;
	text-transform: uppercase;
	padding: 0.85rem 1.2rem;
	display: inline-block;
	margin-top: 1rem;
	border-radius: 4px;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}
.landing .content p.iw.text {
	color: rgb(83, 83, 83);
}
