.labs-detail-view {
	background: #f8f8f8;
	width: 100%;
	height: 100%;
	color: #777777;
	font-family: 'Open Sans';
	padding: 0.15rem 0rem;
}
.labs-detail-block {
	margin: 0.55rem 0 0;
	padding: 0.95rem 2rem 1.7rem;
	background: #fff;
}
.labs-detail-block--small {
	padding: 0.42rem 0.95rem;
	display: flex;
	text-align: right;
	justify-content: space-between;
}
.details-text {
	line-height: 1.56rem;
	margin: 0 0 0.5rem;
}
.ui.header.details-header {
	font-family: 'Open sans';
	font-weight: 400;
	font-size: 1.7rem;
}
.ui.header.details-header.details-header--error {
	color: #ab3a3a;
}
.labs-detail-block .ui.icon.button.detail-block-btn {
	background: transparent !important;
	border: none;
	padding: 0;
	font-size: 1.2rem;
	font-weight: normal;
	margin: 0 0.55rem;
	transition: 270ms linear;
	display: inline-block;
}
.labs-detail-block .ui.icon.button.detail-block-btn:active {
	opacity: 0.3;
}
.labs-detail-block .ui.icon.button.detail-block-btn .icon {
	margin-left: 1rem !important;
}
