.claim-form section.report {
	width: 768px;
	margin: auto;
	background: white;
}
.claim-form section.report .section {
	border: 1px solid rgba(#000000, 0.5);
	padding: 16px;
}
.claim-form section.report .section:first-child {
	border-bottom: none;
}
.claim-form section.report .ui.table {
	border-radius: 0 !important;
}
.claim-form section.report .section .box.celled {
	border: 1px solid rgba(#000000, 0.1);
}
.claim-form section.report .section .box.celled span {
	padding: 8px 10px;
	display: inline-block;
}
.claim-form section.report .section .box.celled span:first-child {
	color: #666;
	border-right: 1px solid rgba(#000000, 0.1);
	font-size: 14px;
}
.claim-form section.report .section h2.title {
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	margin: 0 0 10px;
	text-transform: uppercase;
}
.claim-form section.report .section h2.title.small {
	font-size: 16px;
	text-align: left;
}
.claim-form section.report .section div.header {
	display: grid;
	grid-template-columns: 25% 50% 25%;
	text-align: center;
	padding-bottom: 1.25rem;
}
.claim-form section.report .section img.image {
	max-width: 75px;
	display: block;
	margin: auto;
}
.claim-form section.report .section h3,
.claim-form section.report .section h4 {
	margin: 0;
	font-weight: normal;
	font-size: 1.12rem;
}
.claim-form section.report .section .form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 80%;
	margin: 1.25rem auto 1rem;
	grid-gap: 2rem;
}
.claim-form section.report .section .form.unspaced {
	max-width: 100%;
	margin: 0;
}
.claim-form section.report .section .form.tabled {
	grid-template-columns: 2fr 1fr;
	align-items: end;
}
.claim-form section.report .section .form.one {
	grid-template-columns: 1fr;
}
.claim-form section.report .section .form.end {
	align-items: end;
}
.claim-form section.report .section .form .field {
	font-size: 0.945rem;
	text-transform: uppercase;
	margin-bottom: 0.55rem;
	display: flex;
	align-items: flex-start;
	width: 100%;
}
.claim-form section.report .section .form .field.close-up {
	margin-top: 0;
}
.claim-form section.report .section .form .field.close-down {
	margin-bottom: 0;
}
.claim-form section.report .section .form .field.mg-d {
	margin-bottom: 1rem;
}
.claim-form section.report .section .form .field .label {
	/* font-weight: 600; */
	width: 145px;
	display: inline-block;
	text-transform: capitalize;
	color: #666;
}
.claim-form section.report .section .form .field .bordered {
	display: block;
	border: 1px solid rgba(#000000, 0.1);
	flex: 1;
	padding: 0.25rem 0.55rem;
	font-size: 1rem;
	box-sizing: border-box;
	height: 30px;
}
.claim-form section.report .section .form .field .bordered.large {
	height: 40px;
}
.claim-form section.report .section .form.less .field .label {
	width: 100px;
}
.claim-form section.report .section .second .field .label {
	width: 120px;
}
.claim-form section.report .section small.footer-text {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-size: 0.55rem;
	margin-bottom: -1rem;
}
.claim-form section.report .section small.footer-text.large {
	font-size: 0.87rem;
	font-weight: bold;
}
.claim-form section.report .headings {
	text-align: center;
	margin-top: 0.25rem;
}
.claim-form section.report .headings h4 {
	margin: 0;
	font-weight: normal;
	font-size: 0.79rem;
}
.claim-form section.report .headings:last-child {
	padding-bottom: 2rem;
}
