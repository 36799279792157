.main-content {
	width: 100%;
	flex: 1;
	position: relative;
	/* overflow: hidden; */
}
.main-content .nav {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100% !important;
	z-index: 10;
	background: white;
	max-width: 100%;
}
.main-content .container.navbar {
	margin: auto;
	padding: 1.35rem 0;
}
.main-content .ui.header.main {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.main-content .ui.header.main .headings {
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	min-width: 400px;
	align-items: baseline;
	padding: 0.55rem 0;
}
.main-content .ui.header.main .headings .title {
	font-size: 2.3rem;
	color: var(--primary-theme-color);
	text-transform: initial;
	margin-right: 10px;
	text-transform: uppercase;
	line-height: 16px;
}
.main-content .ui.header.main .headings .subTitle {
	font-size: 0.57em;
	color: var(--secondary-text-theme-color);
	text-transform: initial;
	letter-spacing: normal;
	line-height: 16px;
}
/* buttons on the header styles */
.main-content .ui.header.main .ui.button.iw.btn-header {
	background: transparent;
	font-family: 'Open sans', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1rem;
	padding: 0.43rem 0.66rem;
	margin: 0;
	margin-right: 1.111rem;
	border-radius: 32px;
}
.main-content .ui.header.main .ui.button.iw.btn-header:last-child {
	margin-right: 0;
}
.main-content-view {
	width: 100%;
	height: 100vh;
	overflow-y: auto;
}
.main-content .view-content {
	overflow-y: auto;
	height: 100vh;
	padding-top: 5.9rem;
}
