/* textbox styles */
.textbox {
	width: 100%;
	position: relative;
	margin: 2rem 0;
}
.textbox textarea {
	width: 100%;
	box-sizing: border-box;
	resize: none;
	height: 100px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 4px;
	font-size: 1.07rem;
	font-family: 'Open sans', 'Lato', sans-serif;
	width: 100%;
	padding: 1.2rem 1.5rem !important;
	font-weight: 100;
	outline: none;
	transition: border 200ms linear;
}
.textbox textarea + label {
	position: absolute;
	left: 1.5rem;
	top: 50%;
	font-size: 1.07rem;
	transform: translateY(-190%);
	transition: 200ms linear;
	color: rgba(0, 0, 0, 0.6);
}
.textbox textarea:focus {
	border: 1px solid rgba(0, 0, 0, 0.25);
}
.textbox textarea:focus + label,
.textbox textarea.active + label {
	padding: 0rem 0.32rem;
	top: -10px !important;
	background: white;
	transform: translate(0);
	font-size: 0.9rem;
	color: teal;
	font-weight: 600;
}
.textbox textarea.active {
	border-color: teal;
}

/* textfield styles */
.textfield {
	margin: 2rem 0;
	box-sizing: border-box;
	width: 100%;
	position: relative;
}
.textfield input.textfield__input {
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 4px;
	font-size: 1.07rem;
	font-family: 'Open sans', 'Lato', sans-serif;
	width: 100%;
	padding: 1.2rem 1.5rem !important;
	font-weight: 100;
	outline: none;
	transition: border 200ms linear;
}
.textfield input.textfield__input::-webkit-input-placeholder {
	font-weight: 100;
	color: rgba(0, 0, 0, 0.125);
}
.textfield input.textfield__input + label {
	position: absolute;
	left: 1.5rem;
	top: 50%;
	font-size: 1.07rem;
	transform: translateY(-50%);
	transition: 200ms linear;
	color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
}
.textfield input.textfield__input:focus {
	border: 1px solid rgba(0, 0, 0, 0.25);
}
.textfield input.textfield__input:focus + label,
.textfield input.textfield__input.active + label {
	padding: 0rem 0.32rem;
	top: -10px !important;
	background: white;
	transform: translate(0);
	font-size: 0.9rem;
	color: teal;
	font-weight: 600;
	cursor: pointer;
}
.textfield input.textfield__input.active {
	border-color: teal;
}

/* widget styles */
.widget {
	background: #fff;
	padding: 1.75rem;
	border-radius: 4px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
}
.widget.small {
	max-width: 70%;
	margin: auto;
}
@media screen and (max-width: 960px) {
	.widget,
	.widget.small {
		max-width: 95% !important;
	}
}
/* container styles */
.container {
	max-width: 85%;
	margin: 1rem auto;
	box-sizing: border-box;
}
@media screen and (max-width: 500px) {
	.container {
		max-width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.container {
		max-width: 90%;
	}
}

@media screen and (max-width: 960px) {
	.container {
		max-width: 80%;
	}
}

/* loader styles */
.iw.loader {
	width: 100%;
	height: 80vh;
	z-index: 100;
}
.iw.loader.processing {
	position: fixed;
	bottom: 20px;
	right: 40px;
	width: initial;
	height: initial;
	background: teal;
	color: #fff;
	padding: 0.75rem 2rem;
	border-radius: 4px;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.45);
}
.iw.loader.processing span.iw.text {
	margin-left: 3rem;
	color: #fff;
	text-transform: capitalize;
}
.iw.loader .ui.loader {
	position: absolute;
	top: 50%;
	left: 50%;
}
.iw.loader.processing .ui.loader {
	top: 50%;
	left: 18%;
}

/* selector styles */
.selector {
	display: flex;
	align-items: center;
	margin-right: 1.44rem;
}
.selector input[type='radio'] {
	appearance: none;
	border: 1px solid rgba(0, 0, 0, 0.1);
	width: 16px;
	height: 16px;
	margin: 0;
	border-radius: 50%;
	position: relative;
	margin-right: 0.85rem;
	outline: none;
	cursor: pointer;
	transition: 250ms linear;
}
.selector input[type='radio']:checked {
	border-color: teal;
}
.selector input[type='radio']:checked:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 6px;
	height: 6px;
	background: teal;
	content: '';
	border-radius: 50%;
}
.selector input[type='radio']:focus:before {
	content: '';
	opacity: 1;
	position: absolute;
	top: 26%;
	left: 27%;
	width: 7px;
	height: 7px;
	background: teal;
	border-radius: 50%;
	animation: active-ripple 250ms linear forwards;
}
@keyframes active-ripple {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(5);
		opacity: 0;
	}
}
.selector input[type='radio'] + label {
	display: inline;
}

/* empty state styles */
.widget.empty.no-data .ui.header {
	font-size: 2.3rem;
	text-transform: capitalize;
	font-weight: 400;
	margin: 2rem 0 1rem;
}
.widget.empty.no-data .iw.text {
	font-size: 1rem !important;
	text-align: center;
}
.widget.empty.no-data img {
	width: 250px;
	margin: auto;
	display: block;
}

/* restricted access */
.ui.header.access-header {
	font-size: 3rem;
	font-weight: 500;
	margin-bottom: 0;
}
.access-text {
	color: rgb(116, 116, 116) !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
	line-height: 1.5rem !important;
	text-align: center;
}

/* date picker styles */
.ui.modal.date-picker {
	position: relative;
}
.ui.modal.date-picker .content.content .Cal__Container__root {
	margin: auto !important;
}
.ui.modal.date-picker .ui.basic.button.action {
	position: absolute;
	top: 2rem;
	right: 0;
	color: white !important;
	border-radius: 50%;
}

.ui.modal.date-picker .ui.basic.button.action:hover,
.ui.modal.date-picker .ui.basic.button.action:active,
.ui.modal.date-picker .ui.basic.button.action:focus {
	/* background: transparent !important; */
	color: teal !important;
}

/* image box styles */
.imagebox {
	width: 140px;
	height: 140px;
	background: rgba(0, 128, 128, 0.425);
	position: relative;
	border-radius: 50%;
	margin: auto;
	color: white;
}
.imagebox h2.text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 5.2rem;
	font-weight: bold;
	text-transform: uppercase;
}
.imagebox.avatar {
	width: 30px;
	height: 30px;
}
.imagebox.avatar h2.text {
	font-size: 0.9rem;
}
.imagebox.medium {
	width: 100px;
	height: 100px;
}

/* text styles */
.iw.text {
	color: #555555;
	font-size: 1rem;
	margin: 1rem 0;
	font-weight: normal;
}
.iw.text.small {
	font-size: 0.65rem;
	margin: 0;
}
.iw.text.light {
	color: rgb(175, 175, 175);
}
.iw.text.danger {
	color: rgb(131, 23, 23);
}
.iw.text.warning {
	color: rgb(95, 71, 3);
}
.iw.text.nospace {
	margin: 0 !important;
}
.iw.text.helper {
	font-size: 0.85rem;
	color: rgb(141, 141, 141);
}
.iw.text.text-box.danger,
.iw.text.text-box.warning {
	color: white;
	background: rgb(172, 37, 37);
	padding: 0.88rem 1.1rem !important;
	border-radius: 6px;
	font-size: 0.89rem;
}
.iw.text.text-box.warning {
	background: rgb(182, 143, 35);
}
/* stats widget styles */
.widget.stats {
	padding: 1.5rem 1.95rem;
}
.widget.stats.shrink {
	padding: 1rem 1.25rem;
}
.widget.widget.stats.inverted {
	color: white;
	background: teal;
}
.widget.stats .ui.header.header {
	font-size: 1.01rem;
	margin-bottom: 0rem;
	text-transform: capitalize;
}
.widget.widget.stats.inverted .ui.header.header {
	color: white;
}
.widget.stats .val-s {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0.75rem 0 0.95rem;
}
.widget.stats .val-s .iw.text.val {
	margin: 0;
	font-size: 2.2rem;
	color: teal !important;
}
.widget.widget.stats.inverted .val-s .val {
	color: white !important;
}
.widget.stats.shrink .val-s .val {
	font-size: 1.8rem;
}
.widget.stats .val-s .icon {
	font-size: 1.2rem;
	color: #fbbd08;
}
.widget.stats .iw.text.desc {
	font-size: 0.75rem;
	margin: 0 0 0;
	font-family: 'Montserrat';
	text-align: right;
	color: rgb(175, 175, 175) !important;
}
/* ==================== Responsive table ====================== */
@media screen and (max-width: 960px) {
	.responsive-table {
		overflow-x: scroll;
		min-height: 100%;
	}
}

@media screen and (min-width: 500px) {
	.responsive-table {
		overflow-x: auto;
		min-height: 100%;
	}
}

.secured {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
}
.secured img {
	width: 400px;
	margin-bottom: 2rem;
}
.secured .ui.header.header {
	text-align: center;
	margin-bottom: 0.55rem;
}

/* toolbar button styles */
.toolbar .ui.button.btn {
	background: none;
	font-size: 1rem;
	position: relative;
}
.toolbar .ui.button.btn.primary {
	color: teal;
}
.toolbar .ui.button.btn.danger {
	color: red;
}
.toolbar .ui.button.btn.primary:active::before {
	background: rgb(146, 235, 235);
}
.toolbar .ui.button.btn.danger:active::before {
	background: rgb(233, 128, 128);
}
.toolbar .ui.button.btn:active::before {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	top: 30%;
	left: 40%;
	transform: translate(-50%, -50%);
	animation: active 0.2s linear infinite;
	transform-origin: center;
	border-radius: 100%;
}
@keyframes active {
	0% {
		opacity: 0.45;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(5);
	}
}
