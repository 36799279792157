.ui.button.icon.iw.icon.btn {
	font-size: 1rem;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	position: relative;
	display: inline-block;
	color: white;
	background: rgba(teal, 0.5);
}
.ui.button.icon.iw.icon.btn.large {
	font-size: 1.2rem;
	width: 40px;
	height: 40px;
}
.ui.button.icon.iw.icon.btn.close {
	background: rgba(204, 3, 3, 0.5);
}
.ui.button.icon.iw.icon.btn i.icon::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.region-view .iw.view {
	display: grid;
	grid-template-areas: 'main main main main main';
	height: 100%;
	grid-gap: 1.5rem;
}
.region-view .iw.view .aside {
	grid-area: aside;
}
.region-view .iw.view .aside .rows {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 1.5rem;
}
.region-view .iw.view .main {
	grid-area: main;
}

.region-view .iw.view .main .overview {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 1.5rem;
}

.region-view .iw.view .main .overview .widget {
	grid-column: span 2;
}
.region-view .iw.view .main .overview .widget.span-full {
	grid-column: span 6;
}
.region-view .iw.view .main .overview .widget.span-half {
	grid-column: span 3;
}
.widget.region-overview .ui.button.icon.header-btn {
	padding: 0;
	position: absolute;
	right: 1rem;
	font-size: 1.111rem;
	background: transparent;
	color: rgba(255, 255, 255, 0.568);
}
.widget.region-overview .ui.button.icon.header-btn:hover {
	color: white;
}
.widget.region-overview span.item {
	position: relative;
	padding: 0 !important;
	display: block;
	color: inherit;
}
.widget.region-overview span.item.alert {
	border: 1px solid rgba(teal, 0.125);
	background: rgba(teal, 0.025);
}
.widget.region-overview span.item div {
	height: 2.4rem;
	box-sizing: border-box;
	line-height: 2.4rem;
	padding-left: 0.7rem;
	min-height: 2.4rem;
}

.widget.region-overview span.item .toolbar.header-btn {
	position: absolute;
	right: 0.7rem;
	top: 50%;
	transform: translateY(-50%);
	display: none;
}
.widget.region-overview span.item .toolbar.header-btn .ui.button {
	padding: 0rem 0.25rem;
}
.widget.region-overview span.item:hover .toolbar.header-btn {
	display: block;
}
