.widget.section-overview {
	max-width: 470px;
}
.widget.section-overview .ui.divider.separator {
	margin: 1.4rem 0;
	border-top-color: rgba(0, 0, 0, 0.075);
}
.widget.section-overview .ui.header.heading {
	font-size: 1.2rem;
	text-transform: capitalize;
	font-weight: normal;
	margin-bottom: 0.22rem;
	position: relative;
}
.widget.section-overview .ui.header.heading .sub.header {
	letter-spacing: 0;
	font-size: 0.8rem;
	color: rgb(155, 155, 155);
	text-transform: initial;
	margin-top: 0.133rem;
}
.widget.section-overview .ui.header.heading .actions {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.widget.section-overview .ui.header.heading .actions .link {
	color: teal;
}
.widget.section-overview .main-content {
	position: relative;
	padding: 1rem 0;
	margin-bottom: 2rem;
}
.widget.section-overview .main-content h1.summary {
	position: absolute;
	top: 50%;
	right: -60px;
	transform: translateY(-50%);
	font-size: 5rem;
	background: rgb(56, 141, 141);
	color: white;
	padding: 1.55rem 2.6rem;
	border-radius: 8px;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
	width: 154px;
	height: 134px;
	font-weight: 300;
	text-align: center;
}

.widget.section-overview .main-content h1.summary .ui.loader.loading {
	font-size: 1rem;
}

.widget.section-overview .main-content .content-detail {
	max-width: 250px;
	text-align: center;
	padding-top: 0.55rem;
}
.widget.section-overview .main-content .content-detail .icon {
	font-size: 3.12rem;
	margin: 1.4rem 0;
	color: teal;
}
.widget.section-overview .main-content .content-detail .iw.text {
	font-size: 0.82rem;
}
