.hoc-listView-main {
	margin: 2rem auto;
	height: 100%;
}
.dimmable.hoc-listView-dimmed {
	height: 100%;
}
.dimmed.dimmable.hoc-listView-dimmed .ui.active.visible.dimmer .ui.loader {
	/* top: 32% !important; */
	left: 65%;
}
.ui.header.hoc-listView-header {
	color: teal !important;
	font-weight: 600;
	font-size: 2.5rem;
	text-transform: uppercase;
	font-family: 'Open sans';
	position: absolute;
	top: 50%;
	left: 60%;
	transform: translate(-50%, -50%);
	letter-spacing: 0px;
}
.pushable.hoc-listView-sidebar .ui.wide.right.sidebar {
	width: 450px;
}
.pushable.hoc-listView-sidebar .ui.push.sidebar {
	padding: 0 !important;
	height: 100%;
	background: #f8f8f8;
	color: #777777;
}
.pushable.hoc-listView-sidebar {
	height: 100%;
	overflow-y: auto;
}
.pushable.hoc-listView-sidebar > .pusher {
	height: 100%;
	overflow-y: auto;
}
