.widget.sample.list {
	padding-left: 2rem;
	padding-right: 2rem;
}
.widget.sample.list .ui.header.header {
	margin-bottom: 2.5rem;
}
.widget.sample.list p.text {
	margin: auto;
	margin-bottom: 3.5rem;
	line-height: 1.66em;
	color: #777777;
}
.widget.sample.list .page-block {
	margin: 1.4rem 0;
	text-align: right;
}
.widget.sample.modal {
	max-width: 530px;
	margin: auto;
	color: #777777;
}
.widget.sample.modal .selectors {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 1.7rem;
}
.widget.sample.modal .selectors p {
	margin: 0;
	margin-right: 1rem;
}
.requests .ui.attached.segment {
	border: none;
}
.requests .ui.header.header {
	margin-bottom: 1.12rem;
}
.requests .text {
	margin: 1.12rem 0;
}
.hoc-listView-dimmed .ui.header.client {
	font-size: 2rem;
	color: teal;
	text-transform: capitalize;
}
.requests .toolbox {
	text-align: right;
	margin: 1.4rem 0;
}
.requests .toolbox .ui.button.assign-btn {
	font-size: 1.08rem;
	text-transform: uppercase;
	background: rgb(106, 189, 189);
	font-weight: normal;
	border: none;
	color: white;
	padding: 0.55rem 0.95rem;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.27);
}
.widget.bulk-assign {
	height: 450px;
}
.widget.bulk-assign .ui.header.title {
	text-transform: capitalize;
	font-size: 1.55rem;
	color: rgb(70, 70, 70);
	margin-bottom: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.widget.bulk-assign .ui.header.title .iw.text {
	color: #777777;
	letter-spacing: 0;
	font-size: 0.59em;
	text-transform: none;
	margin: 0;
}
.widget.bulk-assign .ui.selection.dropdown {
	margin: 0;
	padding: 0.75rem 1rem;
}
.widget.bulk-assign .ui.selection.dropdown .dropdown.icon {
	padding: 0.75rem 0.44rem 0.75rem 0;
}
