.signin {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: -350px 100px !important;
}
.signin .container-wrapper {
	margin: initial;
	max-width: 100%;
	background: white;
	min-width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 7px 22px rgba(#000000, 0.112);
	padding: 2rem 2rem;
	border-top: 5px solid teal;
	border-radius: 2px;
}
.signin .container-wrapper.no-bg {
	background: transparent;
}
.signin .container-wrapper .wrapper-form {
	width: 400px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1px;
}
.signin .container-wrapper .wrapper-form.no-bg {
	background: transparent;
}
.signin .container-wrapper .ui.header.title {
	font-size: 2.4rem;
	font-weight: 500;
	flex: 1;
	color: teal !important;
	font-weight: bold;
}
.signin .container-wrapper .ui.header.title.subbed {
	font-size: 0.65rem;
	flex: 0;
	line-height: 1rem;
}
.signin .container-wrapper .ui.header.title.subbed .iw.text {
	font-size: 0.5em !important;
	color: rgb(136, 136, 136) !important;
	margin: 0 !important;
	line-height: 1rem !important;
}
.signin .container-wrapper .iw.text {
	font-size: 0.95rem !important;
	margin-bottom: 1.5rem !important;
	color: teal !important;
}
.signin .container-wrapper .fields {
	width: 100%;
	border-bottom: 1px solid rgba(#000000, 0.1);
}
.signin .container-wrapper .fields .textfield {
	margin: 0 0 2rem;
}
.signin .container-wrapper .ui.button.btn {
	font-size: 1rem;
	font-weight: 500;
	font-family: 'Open sans', sans-serif;
	text-transform: uppercase;
	background: teal;
	color: white;
}
.signin .container-wrapper img.signin-img {
	width: 170px;
	display: block;
	margin-bottom: 2.5rem;
}

@media screen and (max-width: 768px) {
	.signin .container-wrapper {
		flex-direction: column;
	}
	.signin .container {
		margin: auto;
	}
	.signin .container .wrapper {
		padding: 1.5rem;
	}
}
