.requests.toolbar .ui.button.filter-list .icon {
	margin-right: 0.43rem !important;
	color: teal;
}
.requests.toolbar .ui.button.filter-list {
	color: rgb(156, 156, 156);
	font-weight: normal;
}
.ui.dropdown.requests.toolbar {
	margin-right: 1.3rem;
}
