.privacy-policy-content {
	max-width: 700px;
	margin: 2rem auto;
}
.privacy-policy .iw.text {
	line-height: 2rem;
}
.privacy-policy .ui.header.large {
	font-size: 2.5rem;
}
.privacy-policy strong {
	font-size: 1.2rem;
}
