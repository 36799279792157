.dashboard {
	width: 100%;
	display: flex;
	position: relative;
	min-height: 100vh;
	/* overflow-y: hidden; */
}
.dashboard-content {
	flex: 1;
	box-sizing: border-box;
	overflow-y: auto;
}
.dashboard-view {
	overflow-y: auto;
	position: relative;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.dashboard-view-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.75);
	z-index: 100;
	opacity: 0;
	animation: fadeIn 300ms linear forwards;
}
.ui.header.dashboard-header {
	background: #ffffff;
	font-family: 'Open sans';
	padding: 0.87rem 1.5rem;
	color: #517d96;
	margin-bottom: 0;
	position: relative;
	text-transform: uppercase;
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	font-weight: 300;
}
.icon.dashboard-icon {
	font-size: 1.1rem !important;
	color: #777777;
	margin-right: 1.2rem;
	cursor: pointer;
}
.ui.header.dashboard-header .sub.header {
	flex: 1;
	text-align: right;
	text-transform: capitalize;
}
.dashboard .sync {
	position: fixed;
	z-index: 1000;
	bottom: 10%;
	right: 10%;
}
