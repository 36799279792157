.un-authenticate.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
}
.un-authenticate.wrapper .ui.container.contain {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.un-authenticate.wrapper .ui.container.contain img {
	width: 500px;
}
.un-authenticate.wrapper .ui.container.contain .link-btn {
	display: inline-block;
	font-size: 1.2rem;
	text-transform: uppercase;
	background: teal;
	color: white;
	padding: 0.85rem 1.2rem;
	margin: 1rem 0;
	border-radius: 4px;
}
