.region-overview .document-table {
	display: flex;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.035);
	border-radius: 4px;
	overflow: hidden;
}
.region-overview .document-table .document {
	flex: 1;
	border-left: 1px solid rgba(0, 0, 0, 0.035);
}
.region-overview .document-table .document:first-child {
	border-left: none;
}
.region-overview .document-table .document h3.document-title {
	font-size: 1.12rem;
	font-weight: 300;
	margin: 0;
	background: teal;
	color: white;
	padding: 0.68rem 0.88rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.region-overview .document-table .document .document-item {
	padding: 0.68rem 0.88rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: background 0.2s linear;
	cursor: pointer;
}
.region-overview .document-table .document .document-item-wrapper {
	position: relative;
}
.region-overview .document-table .document .document-item:hover,
.region-overview .document-table .document .document-item.selected {
	background: rgba(0, 0, 0, 0.035);
}
.region-overview .document-table .document h3.document-title .ui.icon.button,
.region-overview .document-table .document .document-item-wrapper .ui.icon.button {
	padding: 0;
	background: none;
}
.region-overview .document-item .text {
	outline: none;
}
.region-overview .document-table .document .document-item-wrapper .ui.icon.button {
	color: teal;
	visibility: hidden;
	z-index: 20;
	position: absolute;
	right: 0.88rem;
	top: 0.68rem;
}
.region-overview .document-table .document .document-item-wrapper:hover .ui.icon.button {
	visibility: visible;
}
