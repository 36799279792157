.widget.iw.form {
	max-width: 740px !important;
	margin: auto;
}

.ui.icon.header.header.reduce {
	width: 100%;
	text-align: left;
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0;
}
span.right {
	position: absolute;
	right: 0;
}
.ui.icon.header.header.reduce .ui.button.icon.iw.icon.btn {
	margin: 0;
}
.ui.icon.header.header.reduce .icon {
	font-size: 1rem;
}
