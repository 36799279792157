.navbar {
	width: 100%;
	padding: 0.95rem 0;
	background: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
}
.ui.container.navbar-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0;
	margin-top: 0;
}
.navbar .ui.container.navbar-container img {
	width: 32px;
	margin-right: 2.5rem;
	height: 32px;
}
.navbar .navbar-nav {
	display: flex;
	align-items: center;
	justify-content: center;
}
.navbar .navbar-user {
	text-align: right;
}
.navbar .navbar-user a,
.navbar .navbar-nav a {
	/* text-transform: uppercase; */
	color: teal;
	padding: 0 0.55rem;
	font-size: 1rem;
	outline: none;
	/* font-weight: normal; */
}
.navbar .navbar-nav a:hover,
.navbar .navbar-nav a.active {
	font-weight: bold;
}
.navbar .navbar-links-wrapper {
	flex: 1;
	display: flex;
}
