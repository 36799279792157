.profile.edit-layout {
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 30% 70%;
}
.profile.edit-layout .user,
.profile.edit-layout .form {
	box-sizing: border-box;
	padding: 2rem;
}
.profile.edit-layout .form .ui.header.header {
	color: #444444;
	text-transform: capitalize;
	letter-spacing: 0px;
	margin-bottom: 0.55rem;
}
.profile.edit-layout .form .ui.header.header .sub.header {
	color: #999999;
}
