:root {
	--primary-theme-color: teal;
	--primary-text-theme-color: #777777;
	--secondary-text-theme-color: rgb(155, 155, 155);
}

* {
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	color: #333333;
	font-size: 1rem;
}

.detail-view {
	width: 100%;
	color: #777777;
	font-family: 'Open Sans';
	padding: 0.15rem 0rem;
}
.detail-block {
	margin: 0.55rem 0 0;
	padding: 0.95rem 2rem 1.7rem;
	background: #fff;
}
.detail-block--small {
	padding: 0.42rem 0.95rem;
	display: flex;
	text-align: right;
	justify-content: space-between;
}
.details-text {
	line-height: 1.56rem;
	margin: 0 0 0.5rem;
}
.ui.header.details-header {
	font-family: 'Open sans';
	font-weight: 400;
	font-size: 1.7rem;
}
.ui.header.details-header.details-header--error {
	color: #ab3a3a;
}
.detail-block .ui.icon.button.detail-block-btn {
	background: transparent !important;
	border: none;
	padding: 0;
	font-size: 0.95rem !important;
	font-weight: normal;
	margin: 0 0.55rem;
	transition: 270ms linear;
	display: inline-block;
}
.detail-block .ui.icon.button.detail-block-btn:active {
	opacity: 0.3;
}
.detail-block .ui.icon.button.detail-block-btn .icon {
	margin-left: 1rem !important;
	font-size: inherit !important;
}
.detail-block span.block-header {
	font-family: 'Open sans';
	font-weight: 400;
	text-transform: uppercase;
	color: teal;
	font-size: 1.2rem;
}
.detail-block .detail-text {
	text-transform: capitalize;
	font-weight: 300;
	color: teal;
	margin-right: 0.45rem;
}
.detail-block .ui.header.header {
	margin-bottom: 0.35rem;
}
.detail-block .ix.text span {
	display: inline-block;
	min-width: 120px;
	color: teal;
}
.ui.button.toolbar-btn {
	padding: 0.55rem 0.95rem !important;
	font-family: 'Open sans';
	font-size: 1rem;
	font-weight: 300;
	background: transparent;
	transition: box-shadow 250ms linear;
	border-radius: 32px;
	text-transform: uppercase;
	color: teal;
	margin: 0 0.35rem;
}
.ui.button.ix.btn {
	background: teal;
	color: white;
	padding: 0.8rem 1.12rem !important;
	min-width: 95px !important;
	text-align: center;
}
.ui.button.toolbar-btn:hover {
	background: #e8eff3;
}
.ui.button.toolbar-btn:active {
	opacity: 0.4;
}
.ui.button.toolbar-btn .icon {
	margin-left: 0.95rem !important;
}
.ui.button.toolbar-btn--form {
	color: teal;
}
.ui.button.toolbar-btn--close {
	color: rgb(138, 22, 22);
}
.ui.button.toolbar-btn--close:hover {
	background: rgb(245, 230, 230);
}
.ui.header.header {
	font-weight: 300;
	text-transform: uppercase;
	font-family: 'Open sans', 'Lato', sans-serif;
	margin: 0 0 1.4rem;
	color: teal;
	font-size: 1.45rem;
}
.ui.header.header.header--error {
	color: rgb(138, 22, 22);
}
.ui.header.header.reduce {
	margin-bottom: 0.55rem;
}
.ui.input.input {
	margin: 1.2rem;
}
.ui.input.input input {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-family: 'Open sans';
	font-size: 1rem;
	border-color: #d8d8d8;
}
.ui.selection.dropdown.input {
	width: auto !important;
}
img.image {
	width: 100%;
}
img.small {
	width: 40px;
	height: 40px;
}
img.medium {
	width: 75px;
	height: 75px;
}
img.large {
	width: 100px;
	height: 100px;
}
img.avatar {
	border-radius: 50%;
}
img.thumbnail {
	border-radius: 3px;
}

/* styles for select inputs */
.ui.selection.dropdown {
	padding: 1.2rem 1.5rem;
	font-size: 1.07rem;
	font-weight: 100;
	margin: 2rem 0;
}
.ui.ui.selection.dropdown > .dropdown.icon {
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
}

.ui.pointing.secondary.menu .item {
	color: lightgray;
	font-weight: normal;
	font-size: 1rem;
}
.ui.pointing.secondary.menu .item:hover,
.ui.pointing.secondary.menu .active.item:hover {
	color: rgb(77, 167, 167) !important;
	border-color: teal;
}
.ui.pointing.secondary.menu .active.item {
	border-color: teal;
	color: teal;
}
.ui.selectable.table tr {
	cursor: pointer;
}

.ui.table.basic.widget-table thead tr {
	background-color: teal !important;
}
.ui.table.basic.widget-table thead th {
	font-weight: normal !important;
	font-family: 'Open Sans' !important;
	font-size: 1.2rem !important;
	padding: 0.75rem 1rem !important;
	color: white !important;
}
.ui.table.basic.widget-table tbody tr td {
	padding: 1.15rem 1rem;
}
.widget .pagination {
	text-align: center;
	margin-top: 1.2rem;
}
