.status-form {
	width: 80%;
	margin: 2rem auto;
	text-align: center;
}
.status-form .iw.text {
	margin: 0 0 1rem !important;
	text-align: left;
}
.status-form .status {
	display: flex;
	margin: 1rem 0 2rem;
}
.status-form .ui.selection.dropdown {
	margin: 1rem 0 2rem;
}
