.ui.header.header.iw.large {
	font-size: 1.85rem;
	font-weight: 500;
	letter-spacing: 5px;
}
.ui.header.header.iw.large + p {
	font-size: 1rem !important;
	text-align: center;
}

.iw.lists {
	max-height: 500px;
	overflow-y: auto;
}
.iw.lists::-webkit-scrollbar {
	width: 0;
}
.iw.lists .iw.detail-block.item:last-child {
	margin-bottom: 0 !important;
}
.iw.lists .iw.detail-block.item {
	padding: 0;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.2rem;
	border-radius: 0.2rem;
	margin: auto;
	margin-bottom: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.075);
}
.iw.lists .iw.detail-block.item .iw.image-box {
	width: 150px;
	display: flex;
	justify-content: flex-start;
}
.iw.lists .iw.detail-block.item .iw.image-box .imagebox {
	margin: initial;
}

.iw.lists .iw.detail-block.item .details p {
	margin: 0 0 0.35rem !important;
	color: rgb(110, 110, 110) !important;
	font-size: 0.95rem !important;
}
.iw.lists .iw.detail-block.item .details p span {
	margin-right: 1rem;
	min-width: 80px;
	display: inline-block;
	font-size: 1rem;
	color: lightgray;
}
.iw.lists .iw.detail-block.item .details p.iw.header {
	font-size: 1.27rem !important;
}
.iw.lists .iw.detail-block.item .details p:nth-child(2) {
	font-size: 1.12rem !important;
	color: rgb(92, 92, 92) !important;
}
.iw.lists .iw.detail-block.item .details {
	padding-left: 1rem 1.2rem;
	flex: 1;
}
