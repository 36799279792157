.sidebar {
	width: 250px;
	min-height: 100%;
	background: #f3f3f3;
	color: #303030;
	padding-top: 4.45rem;
	position: relative;
}
.sidebar.collapse {
	width: 65px;
}
.sidebar .wrapper {
	position: fixed;
	width: 65px;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}
.sidebar .link {
	display: block;
	color: #b4b4b4 !important;
	font-size: 1.02rem;
	text-align: center;
	padding: 0.85rem 1.12rem;
	transition: 300ms linear;
	text-transform: capitalize;
	margin: 1rem 0 0;
}
.sidebar .link.sub {
	padding: 0.55rem 1.75rem;
	text-align: left;
	font-size: 0.96rem;
	padding-right: 0;
}
.sidebar .link.sub .icon {
	font-size: 0.96rem !important;
}
.sidebar .link:hover,
.sidebar .link.active {
	color: teal !important;
	z-index: 100;
}
.sidebar .icon {
	margin-right: 1.25rem;
	margin-left: 0.75rem;
	font-size: 1.42rem !important;
}
.sidebar.collapse .icon {
	margin-right: 0;
	margin-left: 0;
}
.sidebar.collapse .link.active .icon {
	font-size: 1.55rem !important;
	z-index: 10;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white !important;
}
.sidebar .link.active {
	height: 45px;
	position: relative;
}
.sidebar .link.active::after {
	content: '';
	position: absolute;
	top: -5px;
	left: 0;
	right: -10px;
	bottom: -5px;
	background: teal;
	z-index: -1;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}
